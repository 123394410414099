import React, { useMemo, useState, useEffect } from 'react';
import { useGetContract } from '../../../helpers/hooks/useGetContract';
import { CONTRACT_NAMES } from '../../../helpers/constants';
import { useCallTransaction } from '../../../helpers/hooks/useCallTransaction';
import { useModal } from '../../../helpers/hooks/useModal';
import { CreateStakingModal } from '../../../components/Modals/CreateStakingModal';
import { useWeb3React } from '@web3-react/core';
import { fromWei } from '../../../helpers/numbers';
import { format } from 'date-fns';

export const MainBanner = ({ isStakeExist = false, stakingState, fetchStakingInfo }) => {
  const [isLoadingClaim, setIsLoadingClaim] = useState(false);
  const [stakingApy, setStakingApy] = useState(0);
  const { account } = useWeb3React();
  const { getContract } = useGetContract();
  const { onCallTransaction, transactionInfo } = useCallTransaction();
  const { openedModal, onClose, onOpen } = useModal();
  const [currentWeekDates, setCurrentWeekDates] = useState(null);

  const claimRewards = async () => {
    if (!isLoadingClaim) {
      setIsLoadingClaim(true);

      try {
        const stakingContract = await getContract(CONTRACT_NAMES.STAKING_POOL);
        const tx = await stakingContract.claim();

        onCallTransaction(tx);
      } catch (e) {
        //
      }

      setIsLoadingClaim(false);
    }
  };

  const fetchStakingAPY = async () => {
    try {
      const stakingContract = await getContract(CONTRACT_NAMES.STAKING_POOL);
      const apy = await stakingContract.getTotalCurrentUserApy('0xc7Cc0b22E980e493f86EC252B6275312E5d06F4E');
      setStakingApy(fromWei(apy[4] * 0.419));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (account) {
      fetchStakingAPY();
    }
  }, [account]);

  const setDateByWeekNumber = () => {
    const dt = new Date();
    const currentWeek = Math.ceil((new Date(dt.getTime()) - new Date(dt.getFullYear(), 0, 1) + 1) / 86400000 / 7);

    const date = new Date(2024, 0, 7);
    date.setDate(date.getDate() - ((date.getDay() + 10) % 7));
    date.setDate(date.getDate() + (currentWeek - 1) * 7);
    date.setDate(date.getDate() - 3);

    const date2 = new Date(2024, 0, 7);
    date2.setDate(date2.getDate() - ((date2.getDay() + 10) % 7));
    date2.setDate(date2.getDate() + (currentWeek - 1) * 7);
    date2.setDate(date2.getDate() + 3);
    setCurrentWeekDates({ monday: format(date, 'dd.MM'), sunday: format(date2, 'dd.MM') });
  };

  useEffect(() => {
    setDateByWeekNumber();
  }, []);

  console.log(stakingState?.apy);

  const isAvailibleApy = stakingState?.apy.length > 0 ? stakingState?.apy?.some((item) => item > 0) : false;

  const renderContent = useMemo(() => {
    if (isStakeExist) {
      return (
        <div className="flex flex-col items-start justify-between py-6 sm:py-6 sm:px-4 w-full sm:h-full sm:max-w-full z-[1]">
          <div className="sm:hidden flex flex-col justify-between space-y-6 bg-[#6850F3] rounded-2xl w-full max-w-[343px] p-4">
            <div className="flex items-center justify-between w-full">
              <span className="text-white leading-[18px] poppins font-medium">Current APY:</span>
              <span className="text-xl text-white leading-[18px] font-semibold poppins">
                {parseFloat(stakingApy * 100)?.toFixed(2)}%
              </span>
            </div>
            {!!currentWeekDates && (
              <span className="text-xs leading-[18px] text-white-500 font-medium">
                Current APY is valid from {currentWeekDates?.monday} to {currentWeekDates?.sunday}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-6 sm:w-full sm:space-y-12">
            <div className="flex items-center space-x-4 sm:w-full sm:justify-between">
              <span className="text-lg text-white poppins font-semibold">Personal Staking Stats</span>
              <img src="/icons/staking/person.svg" />
            </div>
            <div className="flex items-center space-x-6 sm:flex-col sm:space-y-3 sm:space-x-0 sm:w-full">
              <div className="flex flex-col items-start justify-center space-y-6 rounded-2xl bg-white-70 p-4 min-h-[124px] backdrop-blur-large sm:space-y-3 sm:w-full sm:min-h-[104px]">
                <span className="text-white-500 text-base">Staked in all your pools</span>
                <div className="flex items-center space-x-3">
                  <img className="shrink-0 w-12 h-12 sm:w-10 sm:h-10" src="/images/dashboard/profitInfo/frgxLogo.png" />
                  <span className="text-white text-[60px] leading-[48px] font-semibold poppins sm:text-[36px]">
                    {parseFloat(stakingState?.userDep).toFixed(2)}
                  </span>
                  <span className="hidden sm:flex text-medium text-white poppins font-semibold leading-none">FRGX</span>
                </div>
              </div>
              <div className="flex flex-col items-start justify-center space-y-6 rounded-2xl bg-white-70 p-4 min-h-[124px] backdrop-blur-large sm:space-y-3 sm:w-full sm:min-h-[104px]">
                {!!isAvailibleApy ? (
                  <>
                    <span className="text-white-500 text-base">Available rewards in all pools</span>
                    <div className="flex items-center space-x-3">
                      <img
                        className="shrink-0 w-12 h-12 sm:w-10 sm:h-10"
                        src="/images/dashboard/profitInfo/frgxLogo.png"
                      />
                      <span className="text-white text-[60px] leading-[48px] font-semibold poppins sm:text-[36px]">
                        +{parseFloat(parseFloat(stakingState.dividents)?.toFixed(2))}
                      </span>
                      <span className="hidden sm:flex text-medium text-white poppins font-semibold leading-none">
                        FRGX
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="flex items-center justify-center space-x-4 sm:flex-col sm:space-x-0 sm:space-y-3 sm:w-full">
                    <img src="/icons/staking/timer.svg" />
                    <span className="text-base poppins text-white max-w-[282px] sm:max-w-full font-medium sm:text-center">
                      Your first rewards will be credited starting from Monday [12:00 UTC]
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-3 z-[10] sm:w-full">
            {!!isAvailibleApy ? (
              <div className="flex flex-col space-y-3 sm:w-full">
                <div className="flex space-x-2.5">
                  <button
                    onClick={claimRewards}
                    disabled={isLoadingClaim || transactionInfo.isWaiting || !parseFloat(stakingState.dividents)}
                    className="flex items-center justify-center bg-[#EBFF29] font-medium rounded-2xl w-[273px] h-[48px] shadow-item sm:w-full poppins"
                  >
                    Claim
                  </button>
                  <button
                    onClick={onOpen}
                    className="flex-shrink-0 bg-[#EBFF29] h-[48px] w-[48px] rounded-[16px] text-primary-500 flex items-center justify-center"
                  >
                    <img className="w-4 h-4" src="/icons/staking/plusIcon.svg" alt="" />
                  </button>
                </div>
                <span className="text-white text-xs text-white-500 poppins max-w-[275px]">
                  *Available rewards will be sent to your wallet from all pools
                </span>
              </div>
            ) : (
              <button
                onClick={onOpen}
                className="flex items-center justify-center bg-[#EBFF29] font-medium rounded-2xl w-[273px] h-[48px] shadow-item sm:w-full poppins"
              >
                Stake more
              </button>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="flex flex-col space-y-12 justify-center z-[1] sm:w-full sm:px-4 sm:pt-12">
        <div className="flex flex-col space-y-4">
          <span className="text-[52px] text-white poppins font-medium leading-[60px] sm:text-[36px] sm:leading-10 sm:text-center">
            Rewards from <br /> <span className="text-[#D9E854]">staked</span> tokens
          </span>
          <span className="text-white font-light sm:text-center">Simple and secure participation in staking</span>
        </div>
        <button
          onClick={onOpen}
          className="flex items-center justify-center shadow-item bg-secondary-500 py-3.5 rounded-2xl sm:w-full"
        >
          <span className="text-white poppins font-medium">Stake Now</span>
        </button>
      </div>
    );
  }, [isStakeExist, stakingState, isLoadingClaim, transactionInfo, stakingApy, currentWeekDates, isAvailibleApy]);

  return (
    <>
      <div className="hidden sm:flex flex flex-col justify-between space-y-6 bg-[#6850F3] rounded-3xl w-full p-4">
        <div className="flex items-center justify-between w-full">
          <span className="text-white leading-[18px] poppins font-medium">Current APY:</span>
          <span className="text-xl text-white leading-[18px] font-semibold poppins">
            {parseFloat(stakingApy * 100)?.toFixed(2)}%
          </span>
        </div>
        {!!currentWeekDates && (
          <span className="text-xs leading-[18px] text-white-500 font-medium">
            Current APY is valid from {currentWeekDates?.monday} to {currentWeekDates?.sunday}
          </span>
        )}
      </div>
      <div className="w-full min-h-[540px] border-[1px] border-white-100 rounded-3xl overflow-hidden sm:relative sm:min-h-[484px] ">
        <div className="relative flex w-full h-full overflow-hidden claiming-bg pl-6 sm:pl-0 sm:flex-col sm:items-center sm:justify-between">
          {renderContent}
          <img className="absolute right-0 bottom-0 z-[0] sm:hidden" src="/images/staking/bannerFrgx.png" />
        </div>
      </div>
      {openedModal && (
        <CreateStakingModal
          stakingState={stakingState}
          openedModal={openedModal}
          handleCloseModal={onClose}
          fetchStakingInfo={fetchStakingInfo}
        />
      )}
    </>
  );
};
